import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { RevealTitle } from "../components/UX/VisibilitySensor"
import Section from "../components/UX/Section"
import { PageButton } from "../components/UI/Buttons"
import styled from "styled-components"

const Content = styled.div`
  white-space: pre-wrap;

  img {
    width: 90%;
    height: 90%;
    object-fit: contain;
  }
`

const DiscussionTemplate = ({ data }) => {
  const { date, title, author } = data.markdownRemark.frontmatter
  const body = data.markdownRemark.html
  console.log(body)

  return (
    <Layout>
      <Seo title="Discussion" />
      <Section>
        <div className="container">
          <div style={{ textAlign: "left", paddingTop: "12vh" }}>
            <RevealTitle barStyle="right" directionUp={false}>
              <h1 style={{ textAlign: "left" }}>{title}</h1>
              <br></br>
              <p
                style={{
                  gridRowStart: "2",
                  gridColumnStart: "1",
                  alignItems: "end",
                }}
              >
                {author}: {date}
              </p>
            </RevealTitle>
          </div>

          <div className="two-column-wrap">
            <Content dangerouslySetInnerHTML={{ __html: body }} />
            <br></br>
            <PageButton class="right" to="/discussion">
              Back to more Articles
            </PageButton>
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        title
        author
      }
    }
  }
`

export default DiscussionTemplate
